import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const MarinFertilityLandingPage = () => (
  <PhysicianLandingPage
    physician="Marin Fertility"
    institution="Marin Fertility"
    referralCode="MARINFERTILITY"
    physicianURL="https://marinfertilitycenter.com/"
  />
)

export default MarinFertilityLandingPage
